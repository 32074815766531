import { CurrencyPipe, NgClass } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
import { PictureItemComponent } from '../../../../shared/components/ui/picture-item/picture-item.component';

@Component({
  selector: 'app-plan-item',
  standalone: true,
  imports: [
    MatRadioModule,
    CurrencyPipe,
    ReactiveFormsModule,
    NgClass,
    PictureItemComponent
  ],
  templateUrl: './plan-item.component.html'
})
export class PlanItemComponent implements OnInit {
  @Input() item: any;
  @Input() title!: string;
  @Input() product: any;
  @Output() updatePlan: EventEmitter<any> = new EventEmitter();
  private localStorage = inject(LocalstorageService);

  selectedPaymentType: any;
  paymentTypeForm = new FormGroup({
    payment_type: new FormControl(null)
  });

  ngOnInit(): void {
    const plan = JSON.parse(this.localStorage.getItem('plan') || '{}');
    if (plan && plan?.id === this.item.id) {
      this.selectedPaymentType = plan?.paymnet_type;
      this.paymentTypeForm
        .get('payment_type')
        ?.setValue(this.selectedPaymentType.id);
    }
  }

  setPaymentType(event: MatRadioChange, item: any) {
    const selected = this.item.payment_option.find(
      (item: any) => item.id === event.value
    );
    if (selected?.surcharge) {
      const price = selected.prices.find(
        (item: any) => item.id === this.product?.doses
      );
      this.selectedPaymentType = {
        id: selected.id,
        price: price?.price,
        price_title: price?.title,
        surcharge: selected?.surcharge
      };
    } else {
      this.selectedPaymentType = {
        id: selected.id,
        price: this.product?.price?.price * item.quantity,
        price_title: 'full price',
        surcharge: selected?.surcharge
      };
    }
  }

  selectPlan() {
    this.updatePlan.emit({
      plan: this.item,
      paymnetType: this.selectedPaymentType
    });
  }
}
