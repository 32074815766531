import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { LoaderComponent } from 'src/app/shared/components/general/loader/loader.component';
import { NgClass } from '@angular/common';
import { ProductApiService } from 'src/app/core/services/product-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PriceItem } from 'src/app/core/models/models';
import { finalize } from 'rxjs';
import { planTypes } from '../../survey/quiz/plans';
import { PlanItemComponent } from './plan-item/plan-item.component';
import { StepContactItemComponent } from '../../survey/components/step-contact-item/step-contact-item.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { ResponseMessages } from 'src/app/core/constants/config';
import { MessageService } from 'src/app/core/services/message.service';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-product-plans',
  standalone: true,
  templateUrl: './product-plans.component.html',
  imports: [
    LoaderComponent,
    NgClass,
    PlanItemComponent,
    StepContactItemComponent
  ]
})
export class ProductPlansComponent implements OnInit, OnDestroy {
  private productApiService = inject(ProductApiService);
  private route = inject(ActivatedRoute);
  private authService = inject(AuthService);
  private router = inject(Router);
  private messageService = inject(MessageService);
  private localStorage = inject(LocalstorageService);
  isLoading = true;
  plans: any;
  productPrices: PriceItem[] = [];
  product: any;
  selectedPlan: any;

  ngOnInit(): void {
    this.authService.pageIndexLoader();
    this.selectedPlan = JSON.parse(this.localStorage.getItem('plan') || '{}');
    this.checkUserExsists();
  }

  ngOnDestroy(): void {
    this.authService.hidePageElements();
  }

  checkUserExsists() {
    const userId = this.route.snapshot.queryParamMap.get('user_id');
    if (userId) {
      this.authService.userExist(userId).subscribe(
        (response: any) => {
          if (response?.status === 2) {
            this.messageService.setInvalidMessage(
              ResponseMessages.PATIENT_PAID_ORDER
            );
            this.router.navigate(['/']);
          } else {
            this.getProduct();
          }
        },
        error => {
          this.isLoading = false;
          this.messageService.setInvalidMessage(
            ResponseMessages.USER_NOT_FOUND
          );
          this.router.navigate(['/']);
        }
      );
    } else {
      this.messageService.setInvalidMessage(ResponseMessages.USER_NOT_FOUND);
      this.router.navigate(['/']);
    }
  }

  getProduct() {
    const productId = this.route.snapshot.queryParamMap.get('product_id');
    const dose = this.route.snapshot.queryParamMap.get('dose');
    if (productId && dose) {
      this.productApiService
        .getProductBySlug(productId)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (data: any) => {
            const price = data.price.find((item: any) => item.med_id === dose);
            this.product = {
              id: data.id,
              title: data?.title,
              image: data?.image,
              doses: data?.doses,
              price: price
            };
            this.plans = planTypes(price)[data.doses];
          },
          error => {
            this.messageService.setInvalidMessage(
              ResponseMessages.PRODUCT_NOT_FOUND
            );
            this.router.navigate(['/']);
          }
        );
    } else {
      this.isLoading = false;
      this.messageService.setInvalidMessage(ResponseMessages.PRODUCT_NOT_FOUND);
      this.router.navigate(['/']);
    }
  }

  selectPlan(event: any) {
    this.localStorage.setItem(
      'plan',
      JSON.stringify({ id: event.plan?.id, paymnet_type: event?.paymnetType })
    );
    const dose = this.route.snapshot.queryParamMap.get('dose');
    const user_id = this.route.snapshot.queryParamMap.get('user_id');
    const product_id = this.route.snapshot.queryParamMap.get('product_id');
    const temp_token = this.route.snapshot.queryParamMap.get('temp_token');
    const quiz_type = this.route.snapshot.queryParamMap.get('quiz_type');
    const promocode = this.route.snapshot.queryParamMap.get('promocode');
    this.router.navigate(['checkout'], {
      queryParams: {
        quantity: event.plan?.quantity,
        subscription: event.plan?.subscription,
        surcharge: event?.paymnetType?.surcharge,
        dose,
        user_id,
        product_id,
        temp_token,
        quiz_type,
        promocode
      }
    });
  }
}
