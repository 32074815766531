<div class="item-border plan-item sm">
  <div>
    <div class="plan-item-header mb-4">
      @if (product?.image) {
      <div class="img-wrapper-xs">
        <app-picture-item [image]="product?.image" view="admin" />
      </div>
      }
      <div>
        <h3 class="mb-2">{{ item.title }}</h3>
        <p class="text-sm">{{ title }}</p>
      </div>
    </div>
    @if (item.benefits.length) {
    <div class="article-content">
      <ul>
        @for (benefit of item.benefits; track $index) {
        <li>{{ benefit.text }}</li>
        }
      </ul>
    </div>
    } @if (item.payment_option?.length) {
    <h6 class="mb-3">Payment option:</h6>
    <form [formGroup]="paymentTypeForm">
      <mat-radio-group
        formControlName="payment_type"
        (change)="setPaymentType($event, item)"
      >
        @for (option of item.payment_option; track $index) {
        <div
          class="radio-item mb-3"
          [ngClass]="{
            active: selectedPaymentType?.id === option.id
          }"
        >
          <div
            class="d-flex justify-content-between full-width align-items-center"
          >
            <mat-radio-button [value]="option.id">
              {{ option.title }}
            </mat-radio-button>
            @if (!option.surcharge) {
            <h6>
              {{ product?.price?.price * item?.quantity | currency : 'USD' }}
            </h6>
            }
          </div>
          @for (price of option.prices; track $index) { @if (price.id ===
          product?.doses) {
          <div class="mt-2 d-flex ms-3">
            <img
              style="min-width: 62px; object-fit: contain"
              src="assets/images/affirm.png"
              alt=""
            />
            <div class="ms-3" [innerHTML]="price.price_title"></div>
          </div>
          } }
        </div>
        }
      </mat-radio-group>
    </form>
    }
  </div>
  <div>
    <div class="divider mb-3"></div>
    @if (item.payment_option?.length) { @if (selectedPaymentType) {
    <div class="d-flex justify-content-between mb-3">
      <h4>
        {{ selectedPaymentType?.price || 0 | currency : 'USD' }}
      </h4>
      <p>{{ selectedPaymentType.price_title }}</p>
    </div>
    }@else{
    <p
      class="text-center mb-3 mt-3"
      style="min-height: 30px; line-height: 30px"
    >
      Select payment option below
    </p>
    } }@else{
    <div class="d-flex justify-content-between mb-3">
      <h4>
        {{ product?.price?.price || 0 | currency : 'USD' }}
      </h4>
      <p>{{ item.price_title }}</p>
    </div>
    }
    <button
      class="app-button btn-full btn-secondary"
      [disabled]="item.payment_option?.length && !selectedPaymentType"
      (click)="selectPlan()"
    >
      Select Plan
    </button>
  </div>
</div>
